import React from "react"
import { graphql } from "gatsby"
// import Audio from "../components/Audio"
import Container from "../components/Container"
import Markdown from "../components/Markdown"
import Section from "../components/Section"
import Site from "../components/Site"
import TalksFeatured from "../components/TalksFeatured"
import TalksRecent from "../components/TalksRecent"
import { formatLink, capitalize } from '../helpers'
import "../css/talks.scss"

const Talk = ({data}) => {
  const theTalk                 = data.datoCmsTalk
  const allTalks                = [...data.allDatoCmsTalk.edges].map(el => el.node)
  const allFromSameSeries       = [...allTalks].filter(aTalk => aTalk.series.slug === theTalk.series.slug)
  const allSimilarPresentations = [...allTalks].filter(aTalk => aTalk.presentation.slug === theTalk.presentation.slug)
  const allRecentTalks          = allFromSameSeries.length > 1 ? allFromSameSeries : allSimilarPresentations
  const formattedMoreLink       = allFromSameSeries.length > 1 ? formatLink(theTalk.series.slug, 'se') : formatLink(theTalk.presentation.slug, 'p')
  const formattedTitle          = allFromSameSeries.length > 1 ? `From This Series` : `Other ${capitalize(theTalk.presentation.slug)}`
  const recentTalksCount        = 10

  return (
    <Site
      title      = {`${theTalk.title} | Talks`}
      className  = "hcc-talks hcc-talks-talk"
      themeColor = "#141414"
    >
      <article>
        <Section className="hcc-talks-featured-section">
          <TalksFeatured data={theTalk} single={true}/>
        </Section>
        {!! theTalk.discussion &&
          <Section className="hcc-talks-notes">
            <Container>
              <h2 className="hcc-talks-content-title">Discussion &amp; Practice</h2>
            </Container>
            <Container className="hcc-grid">
              <Markdown
                tag       = "div"
                type      = {null}
                className = "has-styled-links"
                source    = {theTalk.discussion}
              />
            </Container>
          </Section>
        }
        {!! theTalk.content &&
          <Section className="hcc-talks-notes">
            <Container>
              <h2 className="hcc-talks-content-title">Notes</h2>
            </Container>
            <Container className="hcc-grid">
              <Markdown
                tag       = "div"
                type      = {null}
                className = "has-styled-links"
                source    = {theTalk.content}
              />
            </Container>
          </Section>
        }
        {!! theTalk.audio &&
          <>
          {/*
          <Section className="hcc-talks-audio">
            <Container>
              <h2 className="hcc-talks-content-title">Audio</h2>
              <Audio src={theTalk.audio}/>
            </Container>
          </Section>
          */}
          </>
        }
        <TalksRecent
          data   = {allRecentTalks}
          title  = {formattedTitle}
          offset = {0}
          count  = {recentTalksCount}
          more   = {allRecentTalks.length > recentTalksCount ? formattedMoreLink : null}
        />
      </article>
    </Site>
  )
}

export default Talk

export const query = graphql`
  query TalkQuerySingle($slug : String!) {
    datoCmsTalk(slug: {eq : $slug}) {
      ...TalkFragment
    }
    allDatoCmsTalk(
      sort : {fields : date, order : DESC}
    ) {
      edges {
        node {
          ...TalkFragment
        }
      }
    }
  }
`
