import React from "react"
import PropTypes from "prop-types"
import Container from "../components/Container"
import Img from "../components/Img"
import MediaFigure from "../components/MediaFigure"
import SiteLink from "../components/SiteLink"
import Svg from "../components/Svg"
import Video from "../components/Video"
import { formatLink, formatTime } from "../helpers"

const TalksFeatured = ({ data, showBlurb, showLinks, single }) => {
  let titleContent  = single ? <span>{data.title}</span> : <SiteLink href={formatLink(data.slug, 't')}>{data.title}</SiteLink>
  let speakerFigure = data.speaker.thumbnail !== null ? <Img src={data.speaker.thumbnail.url} alt={data.speaker.name}/> : <Svg type="person"/>

  return (
    <Container className="hcc-talks-featured hcc-grid">
      <div className="hcc-talks-featured-info hcc-grid">
        <strong>{formatTime(data.date, 'talkFull', 'date')}</strong>
        <div>
          <h1>{titleContent}</h1>
          {(showBlurb && data.shortDescription) && <p>{data.shortDescription}</p>}
        </div>
        {showLinks &&
          <ul>
            <li><SiteLink href={formatLink(data.speaker.slug, 'sp')}><MediaFigure>{speakerFigure}</MediaFigure><span>{data.speaker.name}</span></SiteLink></li>
            <li><SiteLink href={formatLink(data.series.slug, 'se')}><MediaFigure><Svg type="book"/></MediaFigure><span>{data.series.title}</span></SiteLink></li>
          </ul>
        }
      </div>
      <div className="hcc-talks-featured-media">
        {data.video &&
          <Video src={data.video} curtain={data.series.image.url} playsinline={true}/>
        }
      </div>
    </Container>
  )
}

TalksFeatured.defaultProps = {
  data      : {},
  showBlurb : false,
  showLinks : true,
  single    : false,
}

TalksFeatured.propTypes = {
  data      : PropTypes.object,
  showBlurb : PropTypes.bool,
  showLinks : PropTypes.bool,
  single    : PropTypes.bool,
}

export default TalksFeatured
