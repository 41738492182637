import React from "react"
import PropTypes from "prop-types"
import Container from "../components/Container"
import Img from "../components/Img"
import MediaFigure from "../components/MediaFigure"
import Scroller from "../components/Scroller"
import Section from "../components/Section"
import SiteLink from "../components/SiteLink"
import { formatLink, formatTime } from '../helpers'

const TalksRecent = ({data, title, offset, count, more}) => {
  return (
    <Section className="hcc-talks-recent" data-datocms-noindex>
      {title &&
        <Container>
          <h2 className="hcc-talks-content-title">{title}</h2>
        </Container>
      }
      <div className="hcc-talks-recent-feed">
        <Scroller showButtons={true}>
          <div className="hcc-flex">
            {data.map((theTalk, i) => {
              if ( i >= offset && i <= ((offset + count) - 1) ) {
                return <SiteLink key={`talks-recent-${theTalk.slug}`} href={formatLink(theTalk.slug, 't')}>
                         <Img src={theTalk.series.image.url} alt={`Series: ${theTalk.series.title}`}/>
                         <div>
                           <h3>{theTalk.title}</h3>
                           <footer>{formatTime(theTalk.date, 'talkFull')}</footer>
                         </div>
                       </SiteLink>
              }
              return false
            })}
            {more &&
              <SiteLink href={more} className="hcc-talks-recent-more">
                <MediaFigure type="rarr"/>
                <strong>View More</strong>
              </SiteLink>
            }
          </div>
        </Scroller>
      </div>
    </Section>
  )
  // {theTalk.speaker.thumbnail !== null &&
  //   <MediaFigure>
  //     <Img src={theTalk.speaker.thumbnail.url} alt={theTalk.speaker.name}/>
  //   </MediaFigure>
  // }
}

TalksRecent.defaultProps = {
  data   : [],
  title  : null,
  offset : 0,
  count  : 3,
  more   : null,
}

TalksRecent.propTypes = {
  data   : PropTypes.array,
  title  : PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  offset : PropTypes.number,
  count  : PropTypes.number,
  more   : PropTypes.string,
}

export default TalksRecent
